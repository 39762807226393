<template>
  <div class="base-form" :style="{ width: width }">
    <div class="btn-box-recall">
      <el-button
        class="recall-button"
        @click="reCallItem()"
        :loading="approvalLoading"
      >
        <!-- 调回批准请求 -->
        {{ $t("label.manageAll.sta4") }}
      </el-button>
      <el-button
        class="recall-button"
        @click="cancel"
        :loading="approvalLoading"
      >
        <!-- 取消 -->
        {{ $t("label.cancel") }}
      </el-button>
    </div>
    <el-form
      v-loading="formData.length"
      :status-icon="statusIcon"
      ref="elForm"
      :model="formData"
      class="clearfix"
      :rules="rules"
      :label-position="labelPosition"
      :disabled="disabled"
    >
      <div
        class="pull-left"
        :style="{ width: item.width || '100%' }"
        v-for="(item) in formAttr"
        :key="item.prop"
      >
        <template v-if="formData && !item.hidden && item.groupShow">
          <div class="only-body">
            <div class="only-can-readonly">
              <div class="title-only">{{ item.label }}</div>
              <div class="content">{{ item.fieldValue }}</div>
            </div>
          </div>
          <p
            class="groupTitle"
            v-if="item.fieldType === 'groupTitle' && !item.hidden"
          >
            {{ item.title }}
          </p>
          <p v-else-if="item.fieldType === 'noData'"></p>
        </template>
      </div>
    </el-form>

    <!-- 查找/查找多选 -->
    <el-dialog
      :title="$t('label.searchs')"
      custom-class="dialog-form-wrapper"
      width="60%"
      top="5%"
      :visible.sync="showSearchTable"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      append-to-body
    >
      <search-table
        ref="searchTable"
        :fieldId="fieldId"
        :checked="checked"
        :relevant-objid="relevantObjId"
        :relevant-prefix="relevantPrefix"
        @changeSelect="changeSelect"
        @setFieldReltaion="setFieldReltaion"
      />
    </el-dialog>
    <el-form :label-position="labelPosition" label-width="390px">
      <!-- 审批人意见 -->
      <el-form-item :label="$t('label.approval.remark')">
        <el-input type="textarea" v-model="approvalAdvice"></el-input>
      </el-form-item>
    </el-form>
    <div class="btn-box-recall">
      <el-button
        class="recall-button"
        @click="reCallItem()"
        :loading="approvalLoading"
      >
        <!-- 调回批准请求 -->
        {{ $t("label.manageAll.sta4") }}</el-button
      >
      <el-button
        class="recall-button"
        @click="cancel"
        :loading="approvalLoading"
      >
        <!-- 取消 -->
        {{ $t("label.cancel") }}</el-button
      >
    </div>
    <div class="return-approval-step">
      <!-- '选择要返回的审批步骤' -->
      <el-dialog
        :title="$t('label.approval.select.step')"
        :visible.sync="isSelect"
        width="550px"
      >
        <return-approval-step
          @changeSelect="changeSelectStep"
          @changeShow="changeShow"
          @submitStep="submitStep"
        ></return-approval-step>
      </el-dialog>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
/*
 * 审批回调页面表单
 */
import returnApprovalStep from "@/views/approval/components/return-approval-step.vue";
import { edictApproval, reCall } from "./api.js";
// import FormUpload from "@/components/Upload/formUpload_Central";
import { IEVersion } from "@/utils/brower";
import detailSendCard from "@/utils/robotMessage.js";
export default {
  name: "base-form",
  props: {
    // 表单中所有select选择器选项集合
    optionList: {
      type: Object,
      default: () => {},
    },
    // 表单label所在位置
    labelPosition: {
      type: String,
      default: "right",
    },
    // 整个表单宽度
    width: {
      type: String,
    },
    // 是否显示验证
    statusIcon: {
      // 是否显示验证
      type: Boolean,
      default: true,
    },
    // 表单数据对象
    formAttr: {
      type: Array,
    },
    // 表单label宽度
    labelWidth: {
      type: Number,
      String,
      default: 120,
    },
    // 处理移出事件
    handleBlur: {
      type: [Function, undefined],
      default: undefined,
    },
    // 值发生变化时是否自动刷新
    autoRefresh: {
      type: Boolean,
      default: true,
    },
    // 是否禁用该表单内的所有组件。若设置为 true，则表单内组件上的 disabled 属性不再生效
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    // FormUpload,
    returnApprovalStep,
  },
  mounted() {
    this.$Bus.$on("deliver-edict-approval",this.ebFn1 );
    this.$Bus.$on("deliver-objectApiName",this.ebFn2 );
  },
  beforeDestroy() {
    this.$Bus.$off("deliver-edict-approval",this.ebFn1 );
    this.$Bus.$off("deliver-objectApiName",this.ebFn2 );
  },
  data() {
    return {
      //判断输入是否为邮箱
      isEmail: (rule, value, callback) => {
        const reg =
          /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!reg.test(value) && value) {
          return callback(new Error("您输入的不是有效的邮箱"));
        } else {
          callback();
        }
      },
      //批准loading
      approvalLoading: false,
      //编辑审批参数
      edictValue: {},
      //提交的类型
      submitType: "",
      //对象名称
      objectApiName: "",
      //审批建议
      approvalAdvice: "",
      // 查找字段相关对象id
      relevantObjId: "",
      // 查找字段相关对象前缀
      relevantPrefix: "",
      // 是否展示查找/查找多选弹窗
      showSearchTable: false,
      //文件id
      fieldId: "",
      // 查找/查找多选字段弹窗中的表格是否允许多选,字段类型为查找多选时才可多选
      checked: false,
      // 当前表格数据
      formData: {}, // 当前表格数据,
      // 验证规则
      rules: {}, 
      // 是否可检索
      filterable: true, 
      // 远程搜索loading
      loading: false, 
      // 远程搜索用于显示的数组
      filterOptions: [], 
      // 当远程搜索频繁改变时用setTimeout减少发送
      timeOut: null, 
      //选择返回的审批步骤
      isSelect: false,
      //审批拒绝后返回第几步
      selectStepId: "",
    };
  },
  created() {
    this.filterFormData();
    // 如果是edge浏览器 将select可搜索改为false
    if (IEVersion() === "edge") {
      this.filterable = false;
    }
  },
  methods: {
    ebFn1(res)  {
      this.edictValue = res;
    },
    ebFn2(res)  {
      this.objectApiName = res;
    },
    /*
     * 调回
     */
    reCallItem() {
      this.approvalLoading = true;
      let option = {
        relatedId: this.edictValue.id,
        comments: this.approvalAdvice,
      };
      reCall(option).then(() => {
        this.approvalLoading = false;
        if (this.edictValue.detailGroupId != "") {
          detailSendCard(this.edictValue.detailGroupId, {
            approval_value: `${localStorage.getItem("username")}调回批准请求`,
            title: this.$i18n.t("vue_label_chat_approval_message"),
            type: "APPROVAL_CARD",
            recordId: this.edictValue.recordId,
            changer: localStorage.getItem("username"),
            // body: [
            //   {
            //     fieldLable: "日期",
            //     fieldValue: transformTime(new Date().getTime())
            //   }
            // ]
          });
        }
        this.$router.go(-1);
        this.$Bus.$emit("cancel-not-loading", true);
      }).catch(()=>{
        this.approvalLoading = false;
      });
    },
    /*
     * 获取审批步骤
     */
    submitStep() {
      this.getApprovalList();
    },
    /*
     * 审批步骤点击取消
     */
    changeShow() {
      this.isSelect = false;
    },
    /*
     * 获取步骤参数
     */
    changeSelectStep(id) {
      this.selectStepId = id;
    },
    /*
     * 编辑审批
     */
    async getApprovalList() {
      let dataArr = this.formData;
      dataArr.id = this.edictValue.objid;
      dataArr = JSON.stringify([dataArr]);
      let option = {
        data: dataArr,
        relatedId: this.edictValue.objid,
        comments: this.approvalAdvice,
        workItemId: this.edictValue.workItemid,
        actionType: this.submitType,
        objectApiName: this.objectApiName,
        stepId: this.selectStepId,
      };
      let result = await edictApproval(option);
      if (result.returnInfo == "Success!") {
        this.$router.push("/approvalCenter");
      } else if (
        result.returnInfo == "Manual" &&
        this.submitType == "Approved"
      ) {
        this.$router.push({
          path: "/approvalCenter/lastApprovalPeo",
          query: { option: option },
        });
      } else if (
        result.returnInfo == "Manual" &&
        this.submitType == "Rejected"
      ) {
        this.isSelect = true;
      }
    },
    /*
     * 批准请求
     */
    submitForm() {
      this.submitType = "Approved";
      this.getApprovalList();
    },
    /*
     * 取消请求
     */
    cancel() {
      this.$router.go(-1);
    },
    /*
     * 拒绝请求
     */
    refuse() {
      this.submitType = "Rejected";
      this.getApprovalList();
    },
    /*
     * 双向绑定
     */
    filterFormData() {
      this.rules = {};
      this.formAttr &&
        this.formAttr.forEach((v, ) => {
          this.$set(this.formData, v.prop, v.value);
          // 如果有验证规则
          if (v.rules && v.rules.length) {
            let name = v.prop;
            v.rules.forEach((val, ) => {
              if (this.rules[name]) {
                this.rules[name].push(val);
              } else {
                let arr = [];
                arr.push(val);
                this.$set(this.rules, name, arr);
              }
            });

            if (v.type === "E") {
              this.rules[name].push({ validator: this.isEmail });
            }
          } else {
            if (v.type === "E") {
              let arr = [{ validator: this.isEmail }];
              this.$set(this.rules, name, arr);
            }
          }
          // 邮箱校验
          // if (v.fieldType === 'E') {
          //   if (this.rules[name]) {
          //     this.rules[name].push({ validator: this.isEmail })
          //   } else {
          //     let arr = []
          //     arr.push({ 'validator': this.isEmail })
          //     this.$set(this.rules, name, arr)
          //   }
          // }
        });
    },
    /*
     * 重置规则
     */
    resetRules() {
      this.rules = {};
      this.formAttr &&
        this.formAttr.forEach((v, ) => {
          // 如果有验证规则
          if (v.rules && v.rules.length) {
            let name = v.prop;
            v.rules.forEach((val, ) => {
              if (this.rules[name]) {
                this.rules[name].push(val);
              } else {
                let arr = [];
                arr.push(val);
                this.$set(this.rules, name, arr);
              }
            });

            if (v.type === "E") {
              this.rules[name].push({ validator: this.isEmail });
            }
          } else {
            if (v.type === "E") {
              let arr = [{ validator: this.isEmail }];
              this.$set(this.rules, name, arr);
            }
          }
        });
    },
    /*
     * 获取表单数据
     */
    getData(callback) {
      // 等待修改
      let index = this.filterOptions.findIndex((item, ) => {
        return this.formData.company === item.value;
      });
      // 改动原因: 需要判断当前选择的公司是否在会员机构之内
      if (index !== -1) {
        this.formData.isCompany = true;
      } else {
        this.formData.isCompany = false;
      }
      this.$refs.elForm.validate((valid) => {
        if (valid) {
          callback(this.formData);
        } else {
          return false;
        }
      });
    },
    /*
     * 清楚验证规则
     */
    resetFiled() {
      this.$refs.elForm && this.$refs.elForm.resetFields();
    },
    /*
     * 查找/查找多选
     */
    remoteSearch(item) {
      this.fieldId = item.fieldId;
      this.relevantObjId = item.objId;
      this.relevantPrefix = item.prefix;
      this.showSearchTable = true;
      this.checked = item.type === "MR" ? true : false;
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    /*
     * 改变查找/查找多选的值
     */
    changeSelect(row) {
      this.showSearchTable = false;
      this.$emit("changeSelect", row, this.fieldId, this.formData);
    },
    /*
     * 设置查找筛选条件
     */
    setFieldReltaion(filterFieldIds) {
      let values = [];
      for (let reltaion in filterFieldIds) {
        let attr = this.formAttr.find((item) => {
          return item.fieldId === filterFieldIds[reltaion].fieldid;
        });
        if (attr !== undefined && Array.isArray(this.formData[attr.prop])) {
          values.push(this.formData[attr.prop].join(";"));
        } else if (attr !== undefined) {
          values.push(this.formData[attr.prop]);
        }
      }
      this.$refs.searchTable.filterConditionVals = values;
    },
    beforeClose() {
      this.showSearchTable = false;
    },
    /*
     * 远程搜索方法
     */
    remoteMethod(query) {
      if (this.formData.isAssociator === "1") {
        if (query !== "") {
          clearTimeout(this.timeOut);
          this.loading = true;
          this.timeOut = setTimeout(() => {});
        } else {
          this.filterOptions = [];
        }
      }
    },
    /*
     * 远程搜索完成选择后清空搜索数组
     */
    clearFilterOptions() {
      this.filterOptions.splice(0);
    },
    /*
     * 远程搜索框失去焦点后清空查询列表
     */
    blurEvent() {
      // this.filterOptions = []
    },
    /*
     * 选项框发生变化
     */
    selectChange( ) {
      this.$emit("selectChange", arguments);
    },
    /*
     * 更改文件
     */
    fileChange(params) {
      this.$emit("fileChange", params);
    },
    /*
     * 内部组件处理鼠标移出事件
     */
    handleBlurInChild(item, val, ) {
      // this.handleBlur && this.handleBlur(...arguments)
      // 同一字段变化可能绑定多事件，如查找带值，依赖关系，动态布局规则
      let changeEvent =
        item.changeEvent !== undefined ? item.changeEvent.split(",") : [];
      changeEvent.forEach((eventItem) => {
        if (eventItem === "control") {
          eventItem && this.$emit(eventItem, val, item.fieldId, this.formData);
        } else {
          eventItem && this.$emit(eventItem);
        }
      });
    },
    /*
     * 值发生改变时候执行的事件
     */
    changeEvent(callback, selectVal, selectItem) {
      // callback && callback(selectVal, selectItem, this.formData)
      // 同一字段变化可能绑定多事件，如查找带值，依赖关系，动态布局规则
      let changeEvent = callback !== undefined ? callback.split(",") : [];
      changeEvent.forEach((eventItem) => {
        if (eventItem === "control") {
          eventItem &&
            this.$emit(eventItem, selectVal, selectItem.fieldId, this.formData);
        } else {
          eventItem &&
            this.$emit(eventItem, selectVal, selectItem, this.formData);
        }
      });
    },
    /*
     * 获取焦点时触发事件
     */
    focusEvent() {},
    /*
     * 富文本内容变更
     */
    onContentChange(callback, content, selectItem) {
      this.formData[selectItem.prop] = content;
    },
    /*
     * 下拉选项框出现时候触发
     */
    visibleChange(flag) {
      // 如果显示  获取所有下拉选项框 并改变他们的最大宽度
      if (flag) {
        let elSelectDropdown =
          document.getElementsByClassName("el-select-dropdown");
        for (let i = 0; i < elSelectDropdown.length; i++) {
          let el = elSelectDropdown[i];
          el.style.maxWidth = el.style.minWidth;
        }
      }
    },
    /*
     * 修改某个属性的键值
     */
    changeProps(props, key, val) {
      for (let i = 0; i < this.formAttr.length; i++) {
        let item = this.formAttr[i];
        if (item.prop === props) {
          item[key] = val;
          break;
        }
      }
    },
  },
  watch: {
    formAttr: {
      handler: function (newValue, ) {
        if (this.autoRefresh) {
          this.filterFormData();
        }
        newValue.forEach((res) => {
          this.formData[res.prop] = res.fieldValue;
        });
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.btn-box-recall {
  display: flex;
  margin-left: 390px;
}
.recall-button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #006dcc !important;
  height: 30px;
  border-radius: 3px;
  border: 1px solid #dddbda;
}
.el-form {
  margin-top: 20px;
}
.only-body {
  margin-left: 140px;
  .only-can-readonly {
    color: #333333;
    margin-bottom: 20px;
    font-size: 12px;
    .title-only {
      min-width: 240px;
      text-align: right;
      display: inline-block;
      margin-right: 10px;
    }
    .content {
      display: inline-block;
      max-width: 450px;
    }
  }
}

.remoteBtn {
  cursor: pointer;

  // &:hover{
  //   color:
  // }
}

.groupTitle {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  padding-left: 20px;
  background: #f0f0f0;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}

.el-input-number {
  width: 100%;
}
</style>
