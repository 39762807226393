<template>
  <div>
    <div class="record-list">
      <div class="line title">
        <div class="item user"></div>
        <div class="item step">
          <!-- 审批步骤 -->
          {{ $t("label.approval.step") }}
        </div>
        <div class="item peo">
          <!-- 被分配人 -->
          {{ $t("label.assigned") }}
        </div>
      </div>
      <div
        class="line"
        v-for="(item, index) in tableList"
        :key="index"
        :style="{
          borderBottom:
            index == tableList.length - 1 ? 'none' : '1px solid #dddbda',
        }"
      >
        <div class="item user" @click="selectCurrentData(index, item)">
          <div class="checkout" v-show="selectDataIndex == index">
            <div class="white-checkout"></div>
          </div>
          <div class="checkout-not" v-show="selectDataIndex != index"></div>
        </div>
        <div class="item step">{{ item.name }}</div>
        <div class="item peo">{{ item.username }}</div>
      </div>
    </div>

    <div class="function-button">
      <div class="return-submit" @click="submitStep">
        <!-- 确定 -->
        {{ $t("label.confirm") }}
      </div>
      <div class="return-cancel" @click="cancelStep">
        <!-- 取消 -->
        {{ $t("label.cancel") }}
      </div>
    </div>
  </div>
</template>

<script>
import { getApprovalPre } from "./api.js";
export default {
  props: {
    relatedId: {
      type: String,
      default: "",
    },
    stepTabList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isSearch: true,
      dialogVisible: true,
      selectDataIndex: null,
    };
  },
  computed: {
    tableList() {
      return this.stepTabList;
    },
  },
  mounted() {
    // this.getSteps();
    this.$Bus.$on("deliver-step-list-arr", this.ebFn);
  },
  beforeDestroy(){
    this.$Bus.$off("deliver-step-list-arr", this.ebFn);
  },
  methods: {
    ebFn(res){
      this.tableList = res;
    },
    selectCurrentData(index, item) {
      this.selectDataIndex = index;
      this.$emit("changeSearch", item.id);
    },
    //获取审批步骤请求
    async getSteps() {
      let option = {
        relatedId: this.relatedId,
      };
      let result = await getApprovalPre(option);
      if (result.result == true && result.data) {
        this.tableList = result.data.list[0].stepList;
      }
    },
    submitStep() {
      this.$emit("submitStep", true);
    },
    cancelStep() {
      this.$emit("changeShow", false);
    },
  },
};
</script>

<style lang='scss'>
.return-approval-step {
  .el-dialog {
    .function-button {
      overflow: hidden;
      font-size: 12px;
      margin-top: 20px;
      .return-cancel {
        cursor: pointer;
        float: right;
        width: 54px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #dcdcdc;
        color: #006dcc;
        margin-right: 15px;
      }
      .return-submit {
        cursor: pointer;
        float: right;
        width: 81px;
        height: 30px;
        background: #1b5297;
        border-radius: 4px;
        text-align: center;
        line-height: 30px;
        color: #fff;
      }
    }
    .record-list {
      width: 100%;
      border-top: 1px solid #dddbda;
      border-bottom: 1px solid #dddbda;
      max-height: 300px;
      overflow: auto;
      .line {
        font-size: 12px;
        width: 100%;
        height: 30px;
        border-left: 1px solid #dddbda;
        border-bottom: 1px solid #dddbda;
        .item.user {
          color: #006dcc;
          cursor: pointer;
          width: 20%;
          text-align: center;
          line-height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
          .checkout-not {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            border: 1px solid #999999;
          }
          .checkout {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: #006dcc;
            display: flex;
            justify-content: center;
            align-items: center;
            .white-checkout {
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background: #fff;
            }
          }
        }
        .item.step {
          width: 50%;
        }
        .item.peo {
          width: 30%;
        }
        .item {
          color: #4d5f76;
          height: 100%;
          line-height: 30px;
          padding-left: 10px;
          border-right: 1px solid #dddbda;
          box-sizing: border-box;
          float: left;
        }
      }
      .line.title {
        margin-top: 0;
        margin-left: 0;
        background: #f8f8f8;
      }
    }
    .el-dialog__header {
      border-bottom: 2px solid #f3f2f2;
    }
  }
}
</style>