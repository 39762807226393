<template>
  <div class="approval-request">
    <div class="approval-body">
      <div class="approval-title">
        <div class="tltle">
          <!-- 调回批准请求 -->
          {{ $t("label.manageAll.sta4") }}
        </div>
        <div class="user">
          <span @click="goToDetail">{{ recordName }}</span>
          <!-- 若要查看当前记录的详细信息，请点左侧链接 -->
          {{ $t("label.process.des") }}
        </div>
      </div>
      <div class="approval-body">
        <div class="title">
          <!-- 批准/拒绝请求 -->
          {{ $t("label.approveorrefuserequest") }}
        </div>
        <div class="body">
          <base-form
            ref="baseForm"
            class="baseForm"
            :form-attr="formAttr"
            :label-width="170"
            :option-list="optionList"
            :status-icon="statusIcon"
            :autoRefresh="autoRefresh"
            @changeSelect="changeSelect"
            @control="control"
          >
          </base-form>
        </div>
      </div>
    </div>
    <div class="shuttle-box">
      <!-- <el-dialog title="选择批准人" -->
      <el-dialog
        :title="$t('label.selectapprover')"
        :visible.sync="isSearch"
        width="500px"
      >
        <shuttle-box @changeSearch="changeSearch"></shuttle-box>
      </el-dialog>
    </div>
    <ApprovalHistory
      :historyID="historyId"
      :showHeader="true"
    ></ApprovalHistory>
  </div>
</template>

<script>
import shuttleBox from "@/views/approval/components/shuttle-box.vue";
// import batchList from "@/views/approval/components/batch-list.vue";
import { approvalMessage } from "./api.js";
import BaseForm from "@/views/approval/components/base-form-recall.vue";
import INPUTTYPE from "@/config/enumCode/inputType";
import ApprovalHistory from "@/views/approval/components/approval-history.vue";

export default {
  name: "distribution-again",
  data() {
    return {
      recordName: "",
      isSearch: false,
      formData: {
        people: "",
        remarks: "",
      },
      title: this.$route.query.value.title,
      historyId: this.$route.query.value.id, // 审批预览id
      //路由获取到的值
      routerValue: this.$route.query.value,
      //提交的类型
      submitType: "",
      formAttr: null,
      optionList: {},
      statusIcon: true,
      autoRefresh: false,
      controlContainer: null,
    };
  },
  computed: {},
  components: {
    shuttleBox,
    // batchList,
    BaseForm,
    ApprovalHistory,
  },
  mounted() {
    document.getElementById("lightning-loading").style.display = "none";
    if (this.$route.path == "/approvalCenter/approvalRecall") {
      document.title = this.$setTitle(this.$i18n.t("label.accraditation"));
    }
    this.$Bus.$emit("deliver-edict-approval", this.$route.query.value);
    this.getFormAttr();
  },
  methods: {
    addFormAttr(title, attr) {
      if (attr.required == 1) {
        attr.rules = [
          {
            required: attr.required,
            message: `${this.$i18n.t("label_tabpage_placeenterz")}${
              attr.label
            }`,
            trigger: "blur",
          },
        ];
      }
      attr.prop = attr.name;
      // 新增/编辑不显示记录类型和所有人
      // if (attr.apiname === 'recordtype' || attr.apiname === 'ownerid') {
      //   attr.hidden = true
      // }
      // if (attr.type !== "AD") {
      if (attr.type !== "AD") {
        this.$set(
          attr,
          "label",
          attr.type === undefined ? attr.fieldLabel : attr.label
        );
        this.$set(
          attr,
          "fieldType",
          INPUTTYPE[attr.type === undefined ? attr.fieldType : attr.type]
        );
        // 创建人和最后修改人不可改
        if (attr.name === "createbyid" || attr.name === "lastmodifybyid") {
          attr.readonly = true;
          attr.data &&
            attr.data.length > 0 &&
            this.$set(attr, "value", attr.data[0].value);
        }
        if (attr.fieldType === "select" || attr.fieldType === "multi-select") {
          let options = [];
          attr.data &&
            attr.data.length > 0 &&
            attr.data.forEach((item) => {
              options.push({
                key: item.value,
                val: item.id,
              });
            });
          this.optionList[attr.prop] = options;
          if (attr.prop === "recordtype") {
            this.optionList[attr.prop] = [
              {
                key: attr.value,
                val: attr.id,
              },
            ];
          }

          if (attr.fieldType === "multi-select") {
            attr.value =
              attr.value === this.$i18n.t("select.nullvalue") ||
              attr.value === "" ||
              attr.value === undefined
                ? []
                : attr.value.split(";");
          }
        } else if (
          attr.fieldType === "remote-select" ||
          attr.fieldType === "remote-multi-select"
        ) {
          // 查找多选字段选项设置
          if (attr.type === "MR" && attr.data && attr.data.length > 0) {
            let multiOptions = [];
            let labels = attr.data[0].value.split(";");
            let values = attr.data[0].id.split(";");

            for (let index = 0; index < labels.length; index++) {
              multiOptions.push({
                label: labels[index],
                value: values[index],
              });
            }
            this.$set(this.optionList, attr.prop, multiOptions);
            attr.value =
              attr.value === this.$i18n.t("select.nullvalue")
                ? []
                : attr.value.split(";");
          } else {
            let multiOptions = [];
            if (attr.data !== undefined) {
              multiOptions.push({
                label: attr.data[0].value,
                value: attr.data[0].id,
              });
            }
            this.$set(this.optionList, attr.prop, multiOptions);
          }
          attr.readonly = true;
        } else if (attr.fieldType === "number") {
          if (Number(attr.decimalPlaces) !== 0) {
            attr.precision = Number(attr.decimalPlaces);
          }
          let decimal =
            attr.decimalPlaces !== undefined && attr.decimalPlaces !== null
              ? 1 / Math.pow(10, Number(attr.decimalPlaces))
              : 0;
          if (attr.fieldLength !== undefined) {
            attr.valueInterval = {
              min:
                0 -
                Math.pow(10, attr.fieldLength - attr.decimalPlaces - 1) +
                decimal,
              max:
                Math.pow(10, attr.fieldLength - attr.decimalPlaces - 1) -
                decimal,
            };
          } else {
            attr.valueInterval = {
              min:
                0 -
                Math.pow(10, attr.length - attr.decimalPlaces - 1) +
                decimal,
              max: Math.pow(10, attr.length - attr.decimalPlaces - 1) - decimal,
            };
          }
        } else if (attr.fieldType === "checkbox") {
          attr.value = attr.value === "true" ? true : false;
        }
        attr.width = "100%";
        attr.groupTitle = title;
        attr.groupShow = true;
        this.formAttr.push(attr);
      }
      if (attr.type == "AD" && attr.readonly == "1") {
        this.$set(
          attr,
          "label",
          attr.type === undefined ? attr.fieldLabel : attr.label
        );
        this.$set(
          attr,
          "fieldType",
          INPUTTYPE[attr.type === undefined ? attr.fieldType : attr.type]
        );
        // 创建人和最后修改人不可改
        if (attr.name === "createbyid" || attr.name === "lastmodifybyid") {
          attr.readonly = true;
          attr.data &&
            attr.data.length > 0 &&
            this.$set(attr, "value", attr.data[0].value);
        }
        if (attr.fieldType === "select" || attr.fieldType === "multi-select") {
          let options = [];
          attr.data &&
            attr.data.length > 0 &&
            attr.data.forEach((item) => {
              options.push({
                key: item.value,
                val: item.id,
              });
            });
          this.optionList[attr.prop] = options;
          if (attr.fieldType === "multi-select") {
            attr.value =
              attr.value === this.$i18n.t("select.nullvalue") ||
              attr.value === ""
                ? []
                : attr.value.split(";");
          }
        } else if (
          attr.fieldType === "remote-select" ||
          attr.fieldType === "remote-multi-select"
        ) {
          // 查找多选字段选项设置
          if (attr.type === "MR" && attr.data && attr.data.length > 0) {
            let multiOptions = [];
            let labels = attr.data[0].value.split(";");
            let values = attr.data[0].id.split(";");

            for (let index = 0; index < labels.length; index++) {
              multiOptions.push({
                label: labels[index],
                value: values[index],
              });
            }
            this.$set(this.optionList, attr.prop, multiOptions);
            attr.value =
              attr.value === this.$i18n.t("select.nullvalue")
                ? []
                : attr.value.split(";");
          } else {
            let multiOptions = [];
            if (attr.data !== undefined) {
              multiOptions.push({
                label: attr.data[0].value,
                value: attr.data[0].id,
              });
            }
            this.$set(this.optionList, attr.prop, multiOptions);
          }
          attr.readonly = true;
        } else if (attr.fieldType === "number") {
          if (Number(attr.decimalPlaces) !== 0) {
            attr.precision = Number(attr.decimalPlaces);
          }
          let decimal =
            attr.decimalPlaces !== undefined && attr.decimalPlaces !== null
              ? 1 / Math.pow(10, Number(attr.decimalPlaces))
              : 0;
          if (attr.fieldLength !== undefined) {
            attr.valueInterval = {
              min:
                0 -
                Math.pow(10, attr.fieldLength - attr.decimalPlaces - 1) +
                decimal,
              max:
                Math.pow(10, attr.fieldLength - attr.decimalPlaces - 1) -
                decimal,
            };
          } else {
            attr.valueInterval = {
              min:
                0 -
                Math.pow(10, attr.length - attr.decimalPlaces - 1) +
                decimal,
              max: Math.pow(10, attr.length - attr.decimalPlaces - 1) - decimal,
            };
          }
        } else if (attr.fieldType === "checkbox") {
          attr.value = attr.value === "true" ? true : false;
        }
        attr.width = "100%";
        attr.groupTitle = title;
        attr.groupShow = true;
        this.formAttr.push(attr);
      } else if (attr.type == "AD" && attr.readonly == "0") {
        // 地址特殊处理
        // attr.children.forEach((item) => {
        // });
      }
      if (attr.isContrField) {
        let changeEvent =
          attr.changeEvent === undefined
            ? "control"
            : `${attr.changeEvent},control`;
        this.$set(attr, "changeEvent", changeEvent);
      }
    },
    getFormAttr() {
      this.formAttr = [];
      this.optionList = {};
      let params = {
        relatedId: this.routerValue.id,
        workItemId: this.routerValue.workItemid,
        actionType: "process",
      };
      // if (this.dialogAttr.type !== "NEW") {
      //   params.id = this.id;
      // }
      // if (this.recordType !== "") {
      //   params.recordType = this.recordType;
      // }

      approvalMessage(params).then((res) => {
        this.recordName = res.data.recordName;
        this.$Bus.$emit("deliver-objectApiName", res.data.objectApiName);
        res.data.fieldInfoList.forEach((group) => {
          // 字段属性
          if(group.fieldProperty){
            group.isContrField = !!group.fieldProperty.isContrField
          }
          this.addFormAttr(group.label, group);

          // let colNum = group.colNum;
          // if (group.showWhenEdit) {
          //   this.formAttr.push({
          //     width: "100%",
          //     type: "groupTitle",
          //     title: group.title,
          //     groupShow: true,
          //     hidden: false,
          //   });
          // }

          // group.data.length > 0 &&
          //   group.data.forEach((line) => {
          //     // if (!(Array.isArray(line.left) && line.left.length === 0)) {
          //     //   this.addFormAttr(group.title, colNum, line.left);
          //     // } else {
          //     //   this.formAttr.push({
          //     //     width: "50%",
          //     //     type: "noData",
          //     //   });
          //     // }
          //     if (
          //       colNum !== 1 &&
          //       !(Array.isArray(line.right) && line.right.length === 0)
          //     ) {
          //       this.addFormAttr(group.title, colNum, line.right);
          //     }
          //   });
        });

        // 强制刷新表单
        this.$refs.baseForm && this.$refs.baseForm.filterFormData();

        // 查找带值，绑定事件
        if (res.data.fieldList !== null && res.data.fieldList !== undefined) {
          this.setLookupValue(JSON.parse(res.data.fieldList));
        }
        // // 依赖关系，绑定事件
        this.controlContainer =
          res.data.controlContainer != undefined
            ? JSON.parse(res.data.controlContainer)
            : [];
        this.setControlContainer();
        // // 动态布局规则，绑定事件
        // this.getDynamicLayoutListDetail(res.data.layoutId);
        // this.isaddShow = true;
      });
    },
     /*
     * 查找带值字段
     */
     setLookupValue(lookupValueExpression) {
      this.lookupValues = [];
      lookupValueExpression.forEach((item) => {
        this.formAttr.forEach((attr, ) => {
          if (attr.apiname === item.expression.split(",")[0].toLowerCase()) {
            attr.changeEvent =
              attr.changeEvent === undefined
                ? "lookupValue"
                : `${attr.changeEvent},lookupValue`;
            // 初始化规则时走规则
            this.isZhucongfield = false;
            this.getLookupValue(item, attr.value);
          }
        });
        this.lookupValues.push(item);
      });
    },
    /*
     * 依赖关系
     */
     setControlContainer() {
      // 先将被控字段选项清空,循环后有对应选项再进行赋值
      this.controlContainer.forEach((control) => {
        control.controlVals.forEach((vals) => {
          vals.dependFields.forEach((dependField) => {
            this.formAttr.forEach((controlAttr, ) => {
              if (controlAttr.fieldId === dependField.dependfieldid) {
                this.optionList[controlAttr.prop] = [];
              }
            });
          });
        });
      });

      this.controlContainer.forEach((control) => {
        // 控制字段
        this.formAttr.forEach((attr) => {
          if (attr.fieldId === control.controlfield) {
            this.control(
              attr.value,
              attr.fieldId,
              this.$refs.baseForm.formData,
              true
            );
          }
        });
      });
    },
     /*
     * 依赖关系，initControl为true时是新建初始化，已经将选项值都清空，无需再次清理
     */
     control(selectVal, fieldId, formData, initControl = false) {
      // 被控制字段集合
      let controledFieldId = [];
      let controledFieldProp = [];
      // 查找对应规则
      let control = this.controlContainer.find((control) => {
        return control.controlfield === fieldId;
      });

      if (control !== undefined) {
        // 先将被控字段选项清空,循环后有对应选项再进行赋值
        if (!initControl) {
          control.controlVals.forEach((vals) => {
            vals.dependFields.forEach((dependField) => {
              this.formAttr.forEach((controlAttr, ) => {
                if (controlAttr.fieldId === dependField.dependfieldid) {
                  controledFieldId.push(controlAttr.fieldId);
                  controledFieldProp.push(controlAttr.prop);
                  this.$set(this.optionList, controlAttr.prop, []);
                }
              });
            });
          });
        }

        // 查找当前值是否有控制规则
        let controlCondition = control.controlVals.find((item) => {
          return item.val === selectVal;
        });
        if (controlCondition === undefined) {
          controledFieldId.forEach((fieldId) => {
            this.formAttr.forEach((controlAttr) => {
              if (controlAttr.fieldId === fieldId) {
                // 清空被控字段值并递归
                if (controlAttr.fieldType === "Q") {
                  this.$set(formData, controlAttr.apiname, []);
                } else {
                  this.$set(formData, controlAttr.apiname, "");
                }
                this.control(formData[controlAttr.apiname], fieldId, formData);
              }
            });
          });

          // control.controlVals.forEach((vals) => {
          //   // 控制值
          //   vals.dependFields.forEach((dependField) => {
          //     this.formAttr.forEach((controlAttr, idx) => {
          //       if (controlAttr.fieldId === dependField.dependfieldid) {
          //         // 清空被控字段值并递归
          //         if (controlAttr.fieldType === 'Q') {
          //           this.$set(formData, controlAttr.apiname, [])
          //         } else {
          //           this.$set(formData, controlAttr.apiname, '')
          //         }
          //         this.control(
          //           formData[controlAttr.apiname],
          //           dependField.dependfieldid,
          //           formData
          //         )
          //       }
          //     })
          //   })
          // })
        } else {
          // 控制选项列表和选项值
          controlCondition.dependFields.forEach((dependField) => {
            this.formAttr.forEach((controlAttr, ) => {
              if (controlAttr.fieldId === dependField.dependfieldid) {
                let options = [];
                dependField.vals.forEach((element, ) => {
                  options.push({
                    key: element.deflangval,
                    val: element.val,
                  });
                });
                this.optionList[controlAttr.prop] = options;

                // 若当前选中值不在选项列表中，则清空
                let option = options.find((item) => {
                  return item.val === formData[controlAttr.apiname];
                });
                if (option === undefined) {
                  // 清空被控字段值并递归
                  if (controlAttr.fieldType === "Q") {
                    this.$set(formData, controlAttr.apiname, []);
                  } else {
                    this.$set(formData, controlAttr.apiname, "");
                  }
                  // 如果被控制字段选项中包含字段默认值，将依赖字段的值赋为默认值
                  if (
                    controlAttr.defaultValue &&
                    controlAttr.defaultValue !== ""
                  ) {
                    let option = options.find((item) => {
                      return item.val === controlAttr.defaultValue;
                    });
                    if (option !== undefined) {
                      this.$set(
                        formData,
                        controlAttr.apiname,
                        controlAttr.fieldType === "Q"
                          ? [controlAttr.defaultValue]
                          : controlAttr.defaultValue
                      );
                    }
                  }
                  this.control(
                    formData[controlAttr.apiname],
                    dependField.dependfieldid,
                    formData
                  );
                }
              }
            });
          });
        }

        // 控制条件
        control.controlVals.forEach(() => {
          // if (vals.val === selectVal) {}
        });
      }
    },
    changeSelect(row, fieldId, formData) {
      let options = [];
      let fieldVal = [];
      if (row instanceof Array) {
        row.forEach((ele) => {
          options.push({
            label: ele.name,
            value: ele.id,
          });
        });
      } else {
        options.push({
          label: row.data.name,
          value: row.data.id,
        });
      }

      this.formAttr.forEach((attr, ) => {
        if (attr.fieldId === fieldId) {
          this.$set(this.optionList, attr.prop, options);
          if (attr.fieldType === "MR") {
            options.forEach((option) => {
              fieldVal.push(option.value);
            });
            formData[attr.apiname] = fieldVal;
          } else {
            formData[attr.apiname] = row.data === undefined ? "" : row.data.id;
          }

          // 若有查找带值，将值带入
          this.lookupValues.forEach((element) => {
            let lookupValue = element.find((item) => {
              return item.apiname === attr.apiname;
            });
            if (lookupValue !== undefined) {
              let lookupFrom = element.find((item) => {
                return item.type === "lookupFrom";
              });
              let lookupTo = element.find((item) => {
                return item.type === "lookupTo";
              });
              formData[lookupTo.apiname] = formData[lookupFrom.apiname];
            }
          });
        }
      });
    },
    //收起弹出框
    changeSearch() {
      this.isSearch = false;
    },
    //查找批准人
    searchPeo() {
      this.isSearch = true;
    },

    //批准请求
    submitForm() {
      this.submitType = "Approved";
      this.batchApprovalRequest();
    },
    //取消请求
    cancel() {
      this.$router.push("/approvalCenter");
    },
    //拒绝请求
    refuse() {
      this.submitType = "Reassign";
      // this.batchApprovalRequest();
    },
    // 跳转详情
    goToDetail() {
      this.$router.push({
        path: `/commonObjects/detail/${this.historyId}/DETAIL`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.approval-request {
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow: auto;
  .approval-body {
    width: 100%;
    .approval-title {
      height: 55px;
      background: #f8f8f8;
      font-weight: 500;
      color: #222222;
      padding-left: 20px;
      padding-top: 5px;
      .tltle {
        font-size: 16px;
      }
      .user {
        font-size: 12px;
        span {
          color: #006dcc;
          cursor: pointer;
        }
      }
    }
    .approval-body {
      .title {
        margin-top: 14px;
        margin-left: 20px;
        font-size: 14px;
        color: #222;
      }
      .body {
        width: 70%;
        margin: 20px auto;
        .left {
          margin-left: calc(50% - 85px);
        }
        .batch-button {
          cursor: pointer;
          display: inline-block;
          //width: 50px;
          margin-right: 15px;
          height: 25px;
          line-height: 25px;
          text-align: center;
          color: #006dcc;
          font-size: 12px;
          border-radius: 3px;
          border: 1px solid #dddbda;
          font-weight: 400;
        }
        .el-form {
          .select-people {
            font-size: 12px;
            font-weight: 400;
            color: #333333;
          }
          .search-button {
            cursor: pointer;
            color: #006dcc;
            font-size: 12px;
            margin-left: 20px;
          }
          .is-have {
            font-size: 12px;
            margin-left: 100px;
          }
          .el-input {
            width: 216px;
          }
          .el-form-item__label {
            font-size: 12px;
            width: 350px;
            color: #333;
          }
          .el-input__inner {
            width: 216px;
            height: 30px;
            line-height: 30px;
          }
          .el-textarea {
            width: 531px;
          }
          .el-textarea__inner {
            height: 163px;
            resize: none;
          }
        }
      }
    }
  }
}
</style>
