<template>
  <div>
    <div class="people-list">
      <div class="hasList">
        <div class="title">可选成员</div>
        <div class="body">
          <div class="input-box">
            <input type="text" v-model="input" />
            <svg class="icon" aria-hidden="true">
              <use href="#icon-search"></use>
            </svg>
          </div>
          <div class="list">
            <div
              class="list-item"
              v-for="(item, index) in filterList"
              :key="item.id"
              @mouseover="mouseOver(index)"
              :style="{
                background: addCurrentIndex == index ? '#f3f6f9' : '#fff'
              }"
            >
              <div class="name">{{ item.name }}</div>
              <div
                class="symbol"
                v-show="addCurrentIndex == index"
                @click="selectPeo(item)"
              >
                <svg class="icon" aria-hidden="true">
                  <use href="#icon-quick_add_aft"></use>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hasList isSelect">
        <div class="title">已选成员</div>
        <div class="body">
          <div class="list">
            <div
              class="list-item"
              v-for="(item, index) in isSelectPeoList"
              :key="item.id"
              @mouseover="isSelectMouseOver(index)"
              :style="{
                background: deleteCurrentIndex == index ? '#f3f6f9' : '#fff'
              }"
            >
              <div class="name">{{ item.name }}</div>
              <div
                class="symbol"
                v-show="deleteCurrentIndex == index"
                @click="deletePeo(index)"
              >
                <svg class="icon" aria-hidden="true">
                  <use href="#icon-jianhao"></use>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box"></div>
    <div class="buttom-button">
      <div class="button submit" @click="submitMessage">
        <!--确定-->{{ $t('label.confirm') }}
      </div>
      <div class="button" @click="cancelSelect">
        <!--取消-->{{ $t('label.cancel') }}
      </div>
    </div>
  </div>
</template>

<script>
  import { getUserList } from './api.js'
  export default {
    data () {
      return {
        peopleList: [],
        input: "",
        //显示添加人员按钮
        shouAdd: false,
        //鼠标当前移入的数据下标
        addCurrentIndex: null,
        deleteCurrentIndex: null,
        //显示删除人员按钮
        shouDelete: false,
        isSelectPeoList: [],
      };
    },
    computed: {
      filterList () {
        if (!this.input) return this.peopleList;
        return this.peopleList.filter((res) => {
          return new RegExp(this.input).test(res.name);
        });
      },
    },
    mounted () {
      this.getUserLists();
    },
    methods: {
      //获取用户列表
      async getUserLists () {
        let result = await getUserList();
        if (result.result == true && result.data) {
          this.peopleList = result.data;
        }
      },
      //删除当前选中的成员
      deletePeo (index) {
        this.isSelectPeoList.splice(index, 1);
      },
      //选择批准人
      selectPeo (item) {
        if (this.isSelectPeoList.length == 5) {
          this.$message({
            showClose: true,
            type: 'warning',
            message: this.$i18n.t('label.redistribution1')
          });
          return false
        }
        let index = this.isSelectPeoList.findIndex(res => res.id == item.id);
        if (index < 0) {
          this.isSelectPeoList.push(item);
        }
      },
      //鼠标移入
      isSelectMouseOver (index) {
        this.deleteCurrentIndex = index;
      },
      //鼠标移入
      mouseOver (index) {
        this.addCurrentIndex = index;
      },
      //取消选择
      cancelSelect () {
        this.$emit("changeSearch", false);
      },
      //确定查找的审批人
      submitMessage () {
        let name = this.isSelectPeoList.map(res => res.name).join();
        let id = this.isSelectPeoList.map(res => res.id).join()
        this.$emit("deliverName", name, id);
        this.$emit("changeSearch", false);
      },
    },
    watch: {

    },
  };
</script>

<style lang='scss'>
  .shuttle-box {
    position: relative;
    .people-list {
      width: 100%;
      padding: 12px;
      padding-top: 0;
      box-sizing: border-box;
      overflow: hidden;
      .hasList {
        float: left;
        width: 200px;
        height: 370px;
        color: #333;
        font-size: 12px;
        .title {
          margin-bottom: 5px;
          font-weight: 500;
        }
        .body {
          border: 1px solid #d8d8d8;
          overflow: auto;
          width: 100%;
          height: 350px;
          border-radius: 3px;
          .list {
            .list-item {
              width: 100%;
              height: 30px;
              line-height: 30px;
              padding-left: 10px;
              .name {
                float: left;
              }
              .symbol {
                float: right;
                margin-right: 15px;
                cursor: pointer;
                .icon {
                  width: 12px;
                  height: 12px;
                }
              }
            }
          }
          .input-box {
            padding: 0 5px;
            box-sizing: border-box;
            position: relative;
            .icon {
              width: 16px;
              height: 16px;
              position: absolute;
              left: 5px;
              bottom: 5px;
            }
            img {
              width: 16px;
              height: 16px;
              position: absolute;
              left: 5px;
              bottom: 5px;
            }
          }
          input {
            width: 100%;
            height: 30px;
            outline: none;
            border: none;
            border-bottom: 1px solid #dcdcdc;
            padding-left: 18px;
          }
        }
      }
      .isSelect {
        float: right;
      }
    }
    .box {
      height: 45px;
    }
    .buttom-button {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 45px;
      background: #f4f6f9;
      border-radius: 3px;
      padding: 12px;
      box-sizing: border-box;
      .button {
        cursor: pointer;
        width: 54px;
        height: 25px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #dcdcdc;
        float: right;
        line-height: 25px;
        text-align: center;
        margin-right: 10px;
        font-size: 12px;
      }
      .submit {
        border: none;
        background: #1b5297;
        color: white;
      }
    }
    .el-dialog {
      margin-top: 40px !important;
      .el-dialog__header {
        border-bottom: 2px solid #f0f0f0;
      }
      .el-dialog__title {
        font-size: 16px;
      }
      .el-dialog__body {
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }
  }
</style>
